var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-4 p-4"},[_c('div',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',_vm._l((_vm.RegulationsInfo),function(item,index){return _c('ul',{key:index},[_c('li',{class:{
              Li_First: _vm.TabIndex == item.id,
              Li_Hover_class: true,
            },staticStyle:{"display":"flex","padding":"10px","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.ChooseParentID(item)}}},[_c('a',{staticClass:"linkBlue",class:{ link: _vm.TabIndex == item.id }},[_c('span',[_vm._v(_vm._s(item.headertext))])]),(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])}),0),_c('div',{class:{ ChangePlace: _vm.changeplace },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}),_c('b-col',{staticClass:"text-left",staticStyle:{"background-color":"white"}},[_c('ul',[_c('li',[(_vm.HeaderText == '')?_c('p',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v(_vm._s(_vm.Regulations.headertext))])]):_vm._e(),_c('p',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v(_vm._s(_vm.HeaderText))])])])]),_vm._l((_vm.parent),function(item,index){return _c('ul',{key:index},[_c('li',{class:{
              Li_First_Second: _vm.TabIndexSecond == item.id,
              Li_Hover_class: true,
            },staticStyle:{"display":"flex","padding":"10px","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.ChooseParentInfoID(item)}}},[_c('a',{staticClass:"linkBlue",class:{ link: _vm.TabIndexSecond == item.id }},[_c('span',[_vm._v(_vm._s(item.headertext))])]),(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])})],2),(this.changeplace)?_c('div',{class:{ ChangePlace: _vm.LineChangePlace },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}):_vm._e(),_c('b-col',{staticStyle:{"background-color":"white"}},[_c('ul',[_c('li',[_c('p',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v(_vm._s(_vm.HeaderTextInfo))])])])]),_vm._l((_vm.parentInfo),function(item,index){return _c('ul',{key:index},[_c('li',{class:{
              Li_First_Third: _vm.TabIndexThird == item.id,
              Li_Hover_class: true,
            },staticStyle:{"display":"flex","padding":"10px","justify-content":"space-between","align-items":"center"}},[_c('a',{staticClass:"linkBlue",class:{ link: _vm.TabIndexThird == item.id },on:{"click":function($event){return _vm.ChooseChild(item)}}},[_c('span',[_vm._v(_vm._s(item.headertext))])]),(_vm.Loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-icon',{attrs:{"icon":"chevron-right"}})],1)])})],2),(this.LineChangePlace)?_c('div',{class:{ ChangePlace: _vm.ChangeLocation },style:({
          borderLeft: '1px solid #000',
          minHeight: '600px',
        })}):_vm._e(),_c('b-col',{attrs:{"sm":"12","lg":"5"}},[_c('h3',{staticClass:"text-center mb-3 pb-3"},[_vm._v(_vm._s(_vm.Regulation.headertext))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.Regulation.maintext)}})])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }