<template>
  <div class="m-4 p-4">
    <!-- <div>
      <b-breadcrumb
        style="background-color:white"
        :items="items"
      ></b-breadcrumb>
    </div> -->
    <div class="mt-3">
      <b-row>
        <b-col>
          <!-- <p style="font-size:20px">
            <b> {{ $t("Regulations") }}</b>
          </p> -->
          <ul v-for="(item, index) in RegulationsInfo" :key="index">
            <li
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;"
              @click="ChooseParentID(item)"
              :class="{
                Li_First: TabIndex == item.id,
                Li_Hover_class: true,
              }"
            >
              <a class="linkBlue" v-bind:class="{ link: TabIndex == item.id }"
                ><span>{{ item.headertext }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: changeplace }"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <b-col style="background-color:white" class="text-left">
          <ul>
            <li>
              <p v-if="HeaderText == ''" style="font-size:20px">
                <b>{{ Regulations.headertext }}</b>
              </p>
              <p style="font-size:20px">
                <b>{{ HeaderText }}</b>
              </p>
            </li>
          </ul>
          <ul v-for="(item, index) in parent" :key="index">
            <li
              @click="ChooseParentInfoID(item)"
              :class="{
                Li_First_Second: TabIndexSecond == item.id,
                Li_Hover_class: true,
              }"
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;"
            >
              <a
                class="linkBlue"
                v-bind:class="{ link: TabIndexSecond == item.id }"
                ><span>{{ item.headertext }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: LineChangePlace }"
          v-if="this.changeplace"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <b-col style="background-color:white;">
          <ul>
            <li>
              <p style="font-size:20px">
                <b>{{ HeaderTextInfo }}</b>
              </p>
            </li>
          </ul>
          <ul v-for="(item, index) in parentInfo" :key="index">
            <li
              :class="{
                Li_First_Third: TabIndexThird == item.id,
                Li_Hover_class: true,
              }"
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;"
            >
              <a
                @click="ChooseChild(item)"
                class="linkBlue"
                v-bind:class="{ link: TabIndexThird == item.id }"
                ><span>{{ item.headertext }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col>
        <div
          :class="{ ChangePlace: ChangeLocation }"
          v-if="this.LineChangePlace"
          :style="{
            borderLeft: '1px solid #000',
            minHeight: '600px',
          }"
        ></div>
        <!-- <b-col>
          <ul>
            <li>
              <p style="font-size:20px">
                <b>{{ HeaderTextInfoChild }}</b>
              </p>
            </li>
          </ul>
          <ul v-for="(item, index) in child" :key="index">
            <li
              :class="{
                Li_First_Second: TabIndexFour == item.id,
                Li_Hover_class: true,
              }"
              style="display: flex; padding:10px;justify-content: space-between; align-items: center;cursor:pointer"
            >
              <a
                @click="ChooseChildInfo(item)"
                class="linkBlue"
                v-bind:class="{ link: TabIndexFour == item.id }"
                ><span>{{ item.headertext }}</span></a
              >
              <b-spinner v-if="Loading" small></b-spinner>
              <b-icon icon="chevron-right"></b-icon>
            </li>
          </ul>
        </b-col> -->
        <b-col sm="12" lg="5">
          <h3 class="text-center mb-3 pb-3">{{ Regulation.headertext }}</h3>
          <div v-html="Regulation.maintext"></div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import RegulationsService from "@/services/Regulations.service";
export default {
  data() {
    return {
      HeaderText: "",
      HeaderTextInfo: "",
      HeaderTextInfoChild: "",
      TabIndexSecond: 0,
      TabIndexThird: 0,
      TabIndexFour: 0,
      parent: [],
      parentInfo: [],
      child: [],
      childIfnfo: [],
      TabIndex: 0,
      Loading: false,
      Regulations: {},
      RegulationsInfo: [],
      // items: [
      //   {
      //     text: this.$t("home"),
      //     to: { name: "Home" },
      //   },
      //   {
      //     text: this.$t("Regulations"),
      //     href: "javascript:history.go(0)",
      //   },
      // ],
      filter: {
        Search: "",
        Lang: "",
        SortColumn: "",
        OrderType: "desc",
        OffSet: "",
        PageNumber: 1,
        PageLimit: 100,
        IsStartPageFromZero: false,
        parentID: "",
      },
      changeplace: false,
      LineChangePlace: false,
      ChangeLocation: false,
      Regulation: {},
    };
  },
  created() {
    this.filter.Lang = localStorage.getItem("lang") || "uz_cyrl";
    this.TabIndex = this.$route.params.id;
    this.Loading = false;
    RegulationsService.Get(this.TabIndex,this.filter.Lang).then((res) => {
      this.Regulations = res.data;
    });

    this.Refresh();
  },
  methods: {
    Refresh() {
      RegulationsService.GetChildList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.OffSet,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero,
        this.TabIndex
      ).then((res) => {
        this.parent = res.data.rows;
        this.Loading = false;
      });
      RegulationsService.GetChildList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.OffSet,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero,
        this.filter.parentID
      ).then((res) => {
        this.RegulationsInfo = res.data.rows;
        this.Loading = false;
        // this.HeaderText = this.RegulationsInfo.filter(
        //   (item) => item.id === this.TabIndex
        // )[0].headertext;
      });

      this.ChooseParentID();
    },
    ChooseParentID(el) {
      this.changeplace = false;
      this.LineChangePlace = false;
      (this.ChangeLocation = false), (this.TabIndex = el.id);
      RegulationsService.GetChildList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.OffSet,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero,
        this.TabIndex
      ).then((res) => {
        this.parent = res.data.rows;
        this.Loading = false;
      });
      // this.RegulationsInfo[0].headertext = item;
      this.HeaderText = el.headertext;
      this.parentInfo = [];
      this.child = [];
      this.HeaderTextInfo = "";
      this.HeaderTextInfoChild = "";
      this.Regulation.maintext = "";
      this.Regulation.text = "";
      this.Regulation.headertext = "";
    },
    ChooseParentInfoID(el) {
      this.LineChangePlace = false;
      this.ChangeLocation = false;
      this.TabIndexSecond = el.id;
      this.changeplace = true;
      RegulationsService.GetChildList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.OffSet,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero,
        this.TabIndexSecond
      ).then((res) => {
        this.parentInfo = res.data.rows;
        this.Loading = false;
      });
      if (el.maintext) {
        // this.$router.push({
        //   name: "Regulation",
        //   params: { id: this.TabIndexSecond },
        // });
        RegulationsService.Get(this.TabIndexSecond,this.filter.Lang).then((res) => {
          this.Regulation = res.data;
        });
      }
      this.child = [];
      this.HeaderTextInfoChild = "";
      this.HeaderTextInfo = el.headertext;
      this.Regulation.maintext = "";
      this.Regulation.text = "";
      this.Regulation.headertext = "";
    },
    ChooseChild(el) {
      this.LineChangePlace = true;
      this.ChangeLocation = false;
      this.TabIndexThird = el.id;
      RegulationsService.GetChildList(
        this.filter.Search,
        this.filter.Lang,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.OffSet,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.IsStartPageFromZero,
        el.id
      ).then((res) => {
        this.child = res.data.rows;
        this.Loading = false;
      });
      this.HeaderTextInfoChild = el.headertext;
      this.Regulation.headertext = "";
      this.Regulation.maintext = "";
      this.Regulation.text = "";
      if (el.maintext) {
        // this.$router.push({
        //   name: "Regulation",
        //   params: { id: this.TabIndexThird },
        // });
        RegulationsService.Get(this.TabIndexThird,this.filter.Lang).then((res) => {
          this.Regulation = res.data;
        });
      }
    },
    ChooseChildInfo(el) {
      this.TabIndexFour = el.id;
      this.childInfo = this.RegulationsInfo.filter(
        (item) => item.parentid === this.TabIndexFour
      );

      this.$router.push({
        name: "Regulation",
        params: { id: this.TabIndexFour },
      });
    },
  },
};
</script>

<style>
.Li_Hover_class:hover {
  background-color: #c7cbce93;
}
.Li_First_Second {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  transition: opacity 0.5s;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 2;
  margin-bottom: 0px !important;
}
.Li_First_Third {
  cursor: pointer;
  display: flex;
  transition: opacity 0.5s;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 3;
  margin-bottom: 0px !important;
}
.Li_First_Second:hover {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: underline;
  padding: 5px;
  transition: opacity 0.5s;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 2;
  margin-bottom: 0px !important;
}
.Li_First_Third:hover {
  cursor: pointer;
  display: flex;
  transition: opacity 0.5s;
  justify-content: space-between;
  text-decoration: underline;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  z-index: 3;
  margin-bottom: 0px !important;
}
.ChangePlaceBack {
  margin-left: 0%;
}
.ChangePlace {
  transition-duration: 800ms;
  margin-left: -20%;
  z-index: 2;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.Li_First {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s;
  padding: 5px;
  font-weight: bold;
  background-color: #70b0ff;
  margin-bottom: 0px !important;
}
.Li_First:active {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #70b0ff;
  margin-bottom: 0px !important;
}
.Li_First:hover {
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #70b0ff;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 0px !important;
}
.Black {
  color: black;
  font-size: 22px;
  font-weight: bold;
}
.linkSecond span {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}

.linkSecond:hover span {
  font-weight: bold;
}

.linkSecond:hover {
  text-decoration: underline;
}
.link span:active {
  color: white;
  text-decoration: underline;
  font-size: 18px;
}

.linkBlue span {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}
.linkBlue span:active {
  color: #1d70b8;
  text-decoration: underline;
  font-size: 16px;
}
/* .linkBlue span:hover {
  color: #1660a2;
  text-decoration: underline;
  font-size: 18px;
} */
.link span {
  color: white;
  text-decoration: underline;
  font-size: 16px;
}

/* .link span:hover {
  color: white;
  font-weight: bold;
} */

.link:hover {
  text-decoration: underline;
}
li {
  display: block;
  /* transition-duration: 0.5s; */
}

li:active {
  cursor: pointer;
  display: block;
}
li:hover {
  cursor: pointer;
}
ul {
  margin-bottom: 1px !important;
}
ul li ul {
  margin-bottom: 0px !important;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  /* transition: all 0.5s ease; */
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:active > ul,
ul li ul:active {
  visibility: visible;
  opacity: 1;
  display: block;
  margin-bottom: 0px !important;
}

ul li ul li {
  margin-bottom: 0px !important;
  clear: both;
  width: 100%;
}
</style>
